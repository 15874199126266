"use client";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { CheckCircledIcon, ClockIcon, CrossCircledIcon, PlayIcon } from '@radix-ui/react-icons';

const EvaluatorPage = ({userDetails}) => {
    const [profiles, setProfiles] = useState([]);
    const [reviewedProfiles, setReviewedProfiles] = useState([]);
    const [unReviewedProfiles, setUnReviewedProfiles] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [file, setFile] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('unReviewed');
    const [isVideoPlay, setIsVideoPlay] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [excelData, setExcelData] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);
    
    const handleFullScreen = (video, e) => {
      handlePlayPauseVideo(e);
      setSelectedVideo(video);
      setIsFullScreen(true);
    };
    
    const handleExitFullScreen = () => {
      setIsFullScreen(false);
    };

    const handlePlayPauseVideo = (event) => {
      if (event.currentTarget) {
        if (event.currentTarget.paused) {
          event.currentTarget.play();
        } else {
          event.currentTarget.pause();
        }
      }
    };
  
    useEffect(() => {
      if (userDetails.role == "Evaluator") {
        const fetchProfiles = async () => {
          try {
            const response = await axios.get(`/api/evaluator?email=${encodeURIComponent(
                userDetails.email
              )}`);
            const userProfiles = response.data.userProfiles;
            setProfiles(userProfiles);
            setUnReviewedProfiles(userProfiles.filter(profile => profile.reviews && profile.reviews.length == 0));
            setReviewedProfiles(userProfiles.filter(profile => {
              if (profile.reviews && profile.reviews.some(review => review.reviewerEmail == userDetails.email)) {
                return profile;
              }
            }));
          } catch (error) {
            console.error('Error fetching profiles:', error);
          }
        };
  
        fetchProfiles();
      }
  
    }, [userDetails.role])
  
    const formatDate = (dateString) => {
      return moment(dateString).format("MM/DD/YYYY"); // Format to short date
    };
    const handleFileChange = (event:any, profile) => {
      setFile(event.target.files[0]);
      setSelectedProfile(profile)
    };

    const viewFeedback = async (profile) => {
      try {
        setSelectedProfile(profile);
        const feedBackUrl = profile.reviews[0].feedBackUrl;
        const response = await axios.get(feedBackUrl, { responseType: 'arraybuffer' });
        const data = new Uint8Array(response.data);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const excelData = XLSX.utils.sheet_to_json(worksheet);
        setExcelData(excelData);
        setIsModalOpen(true);
        // Display the excel data as needed
      } catch (error) {
        console.error('Error fetching feedback:', error);
      }
    };

    const playVideo = (profile) => {
      setIsVideoPlay(true);
      setSelectedVideo(profile.recordingUrl)
    }
    useEffect(() => {
      if (file) {
        const handleUploadFeedback = async () => { 
          const formData = new FormData();
          formData.append('file', file);
          formData.append('_id', selectedProfile._id);
          formData.append('email', userDetails.email);
          const response = await axios.patch('api/evaluator', formData);
          const newUnReviewedProfiles = unReviewedProfiles.filter(profile => profile._id.toString() !== selectedProfile._id)
          const profiles = reviewedProfiles.filter(profile => profile._id.toString() !== selectedProfile._id);
          profiles.push(response.data.userProfile);
          setUnReviewedProfiles(newUnReviewedProfiles);
          setReviewedProfiles(profiles);
          setIsModalOpen(false);
        }
        handleUploadFeedback();
      }
    } , [file]);

    const handleDownloadFeedback = () => {
      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, `${selectedProfile.profileId}.xlsx`);
    }
  
    return (
    <div className="px-20 py-10 max-md:pb-3 max-md:px-0 max-md:py-0 max-md:h-[86vh]">
      <div className="w-full max-md:h-[100%] mx-auto max-md:flex max-md:flex-col">
        <div className='w-full flex px-8 max-md:px-2 max-md:order-2 justify-between'>
          <div className="text-[#496188] max-md:hidden text-2xl font-semibold font-['DM Sans']">Reports</div>
        </div>
        {profiles.length > 0 ? (
          <div className="flex w-full max-md:h-[80%] px-2 max-md:px-0 max-md:order-1 flex-col gap-6">
            <div className={`bg-white max-md:hidden rounded-lg p-6 ${unReviewedProfiles.length > 0 ? "order-1" : "order-2"}`}>
              <div className=" bg-[#dee7f9] mb-4 text-[#496188] px-4 py-2 text-lg font-semibold font-['DM Sans']">
                Videos yet to be reviewed
              </div>
              {
                unReviewedProfiles.length > 0 &&
                <div className='px-8 mb-4 flex justify-between'>
                  <span  className="text-[#8f96a3] text-sm font-medium font-['DM Sans'] leading-tight">User Id</span>
                  <span  className="text-[#8f96a3] relative -left-[1.5rem] text-sm font-medium font-['DM Sans'] leading-tight">Date Recorded</span>
                  <span  className="text-[#8f96a3] relative -left-[1rem] text-sm font-medium font-['DM Sans'] leading-tight">Recording</span>
                  <span  className="text-[#8f96a3] text-sm font-medium font-['DM Sans'] leading-tight">Status</span>
                  <span  className="text-[#8f96a3] text-sm font-medium font-['DM Sans'] leading-tight">Evaluator Feedback</span>
              </div>
              }
              {unReviewedProfiles.length > 0 ?
                unReviewedProfiles.map((profile) => (
                  <div
                    key={profile.profileId}
                    className=" flex justify-between ps-8 pe-2 items-center mb-4 h-[120px] bg-[#f1f2f4] rounded-[10px]"
                  >
                    <div className='w-1/6'>
                      <span className="text-[#496188] text-md font-medium font-['DM Sans'] leading-tight">{profile.profileId}</span>
                    </div>
                    <div className='w-1/6 flex justify-start pe-8'>
                      <span className="w-full text-center text-[#7e8695] text-md font-medium font-['DM Sans'] leading-tight">{profile.recordingDate
                        ? formatDate(profile.recordingDate)
                        : "N/A"}</span>
                    </div>
                    <div className='w-1/4 flex justify-center'>
                      <video
                        controls
                        className="rounded-lg w-60% h-24 object-cover"
                        controlsList="nofullscreen"
                        disablePictureInPicture
                        disableRemotePlayback
                        onClick={(e) => handlePlayPauseVideo(e)}
                        onDoubleClick={(e) => handleFullScreen(profile.recordingUrl, e)}
                      >
                        <source src={profile.recordingUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <div className='w-1/4 flex justify-start items-center'>
                      <div className='flex w-[80%] px-3 py-[7px] justify-center items-center bg-white gap-[5px] inline-flex rounded-full'>
                        <ClockIcon width={20} height={20} className="text-[#FFB821] hover:text-[#FFB821]" />
                        <span className="text-[#496188] text-sm font-medium font-['DM Sans'] leading-tight">Yet to be reviewed</span>
                      </div>
                    </div>
                    <div className='w-1/6 flex justify-center items-center'>
                      <input
                        type="file"
                        id="fileInput"
                        accept=".xlsx"
                        className="hidden"
                        onChange={(e) => handleFileChange(e, profile)}
                      />
                      <label htmlFor="fileInput" className="w-[80%] flex text-sm justify-center text-white items-center h-10 bg-[#1bb560] rounded-full cursor-pointer">
                        Upload Feedback
                      </label>
                      {/* <button
                      onClick={() => handleAddComment(profile)}
                        className="">
                          
                      </button> */}
                    </div>
                  </div>
                )):
                (
                  <p className="text-gray-500 text-center">
                    Nothing to Show.
                  </p>
                )
              }
            </div>
            <div className={`bg-white max-md:hidden rounded-lg p-6 ${reviewedProfiles.length > 0 ? "order-1" : "order-2"}`}>
              <div className=" bg-[#dee7f9] mb-4 text-[#496188] px-4 py-2 text-lg font-semibold font-['DM Sans']">
                Videos reviewed
              </div>
              {
                reviewedProfiles.length > 0 &&
                <div className='px-8 mb-4 flex justify-between'>
                  <span  className="text-[#8f96a3] text-sm font-medium font-['DM Sans'] leading-tight">User Id</span>
                  <span  className="text-[#8f96a3] relative -left-[1.5rem] text-sm font-medium font-['DM Sans'] leading-tight">Date Recorded</span>
                  <span  className="text-[#8f96a3] relative -left-[1rem] text-sm font-medium font-['DM Sans'] leading-tight">Recording</span>
                  <span  className="text-[#8f96a3] text-sm font-medium font-['DM Sans'] leading-tight">Status</span>
                  <span  className="text-[#8f96a3] text-sm font-medium font-['DM Sans'] leading-tight">Evaluator Feedback</span>
                </div>
              }
              {reviewedProfiles.length > 0 ?
                reviewedProfiles.map((profile) => (
                  <div
                    key={profile.profileId}
                    className=" flex justify-between ps-8 pe-2 items-center mb-4 h-[120px] bg-[#f1f2f4] rounded-[10px]"
                  >
                    <div className='w-1/6'>
                      <span className="text-[#496188] text-md font-medium font-['DM Sans'] leading-tight">{profile.profileId}</span>
                    </div>
                    <div className='w-1/6 flex justify-center pe-8'>
                      <span className="w-full text-center text-[#7e8695] text-md font-medium font-['DM Sans'] leading-tight">{profile.recordingDate
                        ? formatDate(profile.recordingDate)
                        : "N/A"}</span>
                    </div>
                    <div className='w-1/4 flex justify-center items-center'>
                      <video
                        controls
                        controlsList="nofullscreen"
                        disablePictureInPicture
                        disableRemotePlayback
                        onClick={(e) => handlePlayPauseVideo(e)}
                        onDoubleClick={(e) => handleFullScreen(profile.recordingUrl, e)}
                        className="rounded-lg w-70% h-24 object-cover"
                      >
                        <source src={profile.recordingUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <div className='w-1/5 flex justify-center items-center'>
                      <div className='flex w-[60%] px-3 py-[7px] justify-center items-center bg-white gap-[5px] inline-flex rounded-full'>
                        <CheckCircledIcon width={20} height={20} className="text-[#1BB661] hover:text-[#1BB661]" />
                        <span className="text-[#496188] text-sm font-medium font-['DM Sans'] leading-tight">Reviewed</span>
                      </div>
                    </div>
                    <div className='w-1/5 flex justify-center items-center'>
                      <button
                      onClick={() => viewFeedback(profile)}
                        className="w-[80%] flex text-sm justify-center text-white items-center h-10 bg-[#1bb560] rounded-full">
                          View Feedback
                      </button>
                    </div>
                  </div>
                )):
                (
                  <p className="text-gray-500 text-center">
                    Nothing to Show.
                  </p>
                )
              }
            </div>
            <div className="h-full hidden max-md:block">
              <div className="flex">
                <button
                  onClick={() => setActiveTab('unReviewed')}
                  className={`flex-1 h-12 py-2 text-center border-b-2 font-medium text-sm font-['DM Sans'] ${
                    activeTab === 'unReviewed'
                      ? 'text-[#1bb560] bg-[#dff9f1] border-[#1bb560]'
                      : 'text-[#b3bdcf] border-transparent bg-white hover:text-blue-600'
                  } transition-all duration-300`}
                >
                  Videos yet to be reviewed
                </button>
                <button
                  onClick={() => setActiveTab('reviewed')}
                  className={`flex-1 h-12 py-2 text-center text-sm font-['DM Sans'] border-b-2 font-medium ${
                    activeTab === 'reviewed'
                      ? 'text-[#1bb560] bg-[#dff9f1] border-[#1bb560]'
                      : 'text-[#b3bdcf] border-transparent bg-white hover:text-blue-600'
                  } transition-all duration-300`}
                >
                  Videos reviewed
                </button>
              </div>

              <div className="h-[90%]">
                {activeTab === 'unReviewed' && (
                  <div className='h-full'>
                    {
                      unReviewedProfiles.length > 0 &&
                      <div className='px-4 py-4 flex justify-between'>
                        <span  className="text-[#8f96a3] text-sm font-medium font-['DM Sans'] leading-tight">User Id</span>
                        <span  className="text-[#8f96a3] text-sm font-medium font-['DM Sans'] leading-tight">Date Recorded</span>
                        <span  className="text-[#8f96a3] text-sm font-medium font-['DM Sans'] leading-tight">Recording</span>
                        <span  className="text-[#8f96a3] text-sm font-medium font-['DM Sans'] leading-tight">Status</span>
                      </div>
                    }
                    <div className='h-[93%] overflow-y-auto'>
                    {unReviewedProfiles.length > 0 ?
                      unReviewedProfiles.map((profile) => (
                      <div
                        key={profile.profileId}
                        className="flex flex-col mb-4 p-4 bg-[#f1f2f4]"
                      >
                        <div className='flex justify-between items-center px-2'>
                          <div>
                            <span className="text-[#496188] text-sm font-medium font-['DM Sans'] leading-tight">{profile.profileId}</span>
                          </div>
                          <div>
                            <span className="w-full text-center text-[#7e8695] text-sm font-medium font-['DM Sans'] leading-tight">{profile.recordingDate
                              ? formatDate(profile.recordingDate)
                              : "N/A"}</span>
                          </div>
                          <div>
                            <div onClick={() => playVideo(profile)} className='h-6 px-3 py-0.5 bg-[#f77668] rounded-full cursor-pointer justify-start items-center gap-0.5 inline-flex'>
                              <div className="text-white text-xs font-medium font-['DM Sans'] leading-tight">Play</div>
                              <PlayIcon width={20} height={20} className="text-white hover:text-white" />
                            </div>
                          </div>
                          <div className='flex bg-white rounded-full'>
                            <ClockIcon width={20} height={20} className="text-[#FFB821] hover:text-[#FFB821]" />
                          </div>
                        </div>
                        <div className='w-full mt-2 flex justify-center items-center'>
                          <input
                            type="file"
                            id="fileInput"
                            accept=".xlsx"
                            className="hidden"
                            onChange={(e) => handleFileChange(e, profile)}
                          />
                          <label htmlFor="fileInput" className="w-full flex text-sm justify-center text-white items-center h-10 bg-[#1bb560] rounded-full cursor-pointer">
                            Upload Feedback
                          </label>
                        </div>
                      </div>
                    )):
                    (
                      <p className="text-gray-500 text-center">
                        Nothing to Show.
                      </p>
                    )
                    }
                    </div>
                  </div>
                )}
                {activeTab === 'reviewed' && (
                <div className='h-full'>
                  {
                    reviewedProfiles.length > 0 &&
                    <div className='px-4 py-4 flex justify-between'>
                      <span  className="text-[#8f96a3] text-sm font-medium font-['DM Sans'] leading-tight">User Id</span>
                      <span  className="text-[#8f96a3] text-sm font-medium font-['DM Sans'] leading-tight">Date Recorded</span>
                      <span  className="text-[#8f96a3] text-sm font-medium font-['DM Sans'] leading-tight">Recording</span>
                      <span  className="text-[#8f96a3] text-sm font-medium font-['DM Sans'] leading-tight">Status</span>
                    </div>
                  }
                  <div className='h-[93%] overflow-y-auto'>
                    {reviewedProfiles.length > 0 ?
                      reviewedProfiles.map((profile) => (
                      <div
                        key={profile.profileId}
                        className="flex flex-col mb-4 p-4 bg-[#f1f2f4]"
                      >
                        <div className='flex justify-between items-center px-2'>
                          <div>
                            <span className="text-[#496188] text-sm font-medium font-['DM Sans'] leading-tight">{profile.profileId}</span>
                          </div>
                          <div>
                            <span className="w-full text-center text-[#7e8695] text-sm font-medium font-['DM Sans'] leading-tight">{profile.recordingDate
                              ? formatDate(profile.recordingDate)
                              : "N/A"}</span>
                          </div>
                          <div>
                            <div onClick={() => playVideo(profile)} className='h-6 px-3 py-0.5 bg-[#f77668] rounded-full cursor-pointer justify-start items-center gap-0.5 inline-flex'>
                              <div className="text-white text-xs font-medium font-['DM Sans'] leading-tight">Play</div>
                              <PlayIcon width={20} height={20} className="text-white hover:text-white" />
                            </div>
                          </div>
                          <div className='flex bg-white rounded-full'>
                            <CheckCircledIcon width={20} height={20} className="text-[#1BB661] hover:text-[#1BB661]" />
                          </div>
                        </div>
                        <div className='w-full mt-2 flex justify-center items-center'>
                          <button
                          onClick={() => viewFeedback(profile)}
                            className="w-full flex text-sm justify-center text-white items-center h-10 bg-[#1bb560] rounded-full">
                              View Feedback
                          </button>
                        </div>
                      </div>
                    )):
                    (
                      <p className="text-gray-500 text-center">
                        Nothing to Show.
                      </p>
                    )
                    }
                  </div>
                </div>
                  
                )}
              </div>
            </div>
          </div>
        ) : (
          <p className="text-gray-500 text-center">
            No profile recordings found.
          </p>
        )}
      </div>
       {
        isFullScreen && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex flex-col items-center justify-center z-50 scrollbar-hide">
            <CrossCircledIcon onClick={handleExitFullScreen} width={60} height={60} className="text-white cursor-pointer hover:text-[#f77668]" />
            <div className="rounded-lg h-[80%] w-[80%] mx-auto shadow-lg">
              <video
                controls
                controlsList="nofullscreen nodownload noremoteplayback"
                disablePictureInPicture
                disableRemotePlayback
                className='w-full h-full rounded-[10px] object-cover'
                onClick={(e) => handlePlayPauseVideo(e)}
                onDoubleClick={handleExitFullScreen}
              >
                <source src={selectedVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        )
      }
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-2/3 max-md:w-[90%] max-md:mx-auto max-md:h-[90%]">
            <CrossCircledIcon onClick={() => setIsModalOpen(false)} width={20} height={20} className="text-[#f77668] float-right cursor-pointer hover:text-[#f77668]" />
            <div className="text-black text-center text-xl font-medium font-['DM Sans'] mb-6 leading-tight">
              {selectedProfile.profileId} - {formatDate(selectedProfile.recordingDate)}
            </div>
            <div className="overflow-auto max-h-96 max-md:max-h-[80%]">
              <table className="min-w-full bg-white">
                <thead>
                  <tr>
                    {excelData.length > 0 && Object.keys(excelData[0]).map((key) => (
                      <th key={key} className="py-2 px-4 border-b border-gray-200 bg-gray-100">{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {excelData.map((row, index) => (
                    <tr key={index}>
                      {Object.values(row).map((value:any, i) => (
                        <td key={i} className="py-2 px-4 border-b border-gray-200">{value}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex justify-center space-x-4 mt-6">
              <button
                onClick={handleDownloadFeedback}
                className="px-4 py-2 rounded-full cursor-pointer bg-[#1bb560] text-white"
                >
                Download Feedback
              </button>
            </div>
          </div>
        </div>
      )}
      {isVideoPlay && (
        <div onClick={(e) => {
          e.stopPropagation(); // Prevent event from bubbling up
          setIsVideoPlay(false); // Pass the assistant id when deleting
        }} className="fixed inset-0 bg-gray-900 bg-opacity-50 flex flex-col items-center justify-center z-50">
          <CrossCircledIcon onClick={() => setIsVideoPlay(false)} width={60} height={60} className="text-white hover:text-[#f77668]" />
          <div className="rounded-lg h-1/3 w-[90%] mx-auto mt-4 shadow-lg">
          <video
            controls
            controlsList="nodownload noremoteplayback"
            disablePictureInPicture
            disableRemotePlayback
            onClick={(e) => handlePlayPauseVideo(e)}
            className='w-full h-full rounded-[10px] object-cover'
          >
            <source src={selectedVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          </div>
        </div>
      )}
    </div>
    );
  };
  
  export default EvaluatorPage;